import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import printJS, { PrintTypes } from 'print-js';

import { documents } from 'api';
import { APIDocument } from 'types';
import { base64Image } from 'utils';

interface PrintDocumentProps {
  id?: number;
}

const printType = {
  pdf: 'pdf',
  jpg: 'image',
  docx: 'image',
  xlsx: 'image',
  png: 'image'
};

type PrintType = keyof typeof printType;

const nonPrintJsType: PrintType[] = ['docx', 'xlsx'];

export const usePrintDocument = () => {
  const { t } = useTranslation();
  const { mutateAsync, isLoading } = useMutation(documents.getById);
  const { mutateAsync: getPngMutate, isLoading: isPngLoading } = useMutation(documents.getPng);

  const print = async ({ id }: PrintDocumentProps) => {
    if (!id) return;

    const data = await mutateAsync(id);

    const type = printType[data?.file_type as PrintType] as PrintTypes;
    const printable = data?.original_file || data?.pdf_file;

    if (!type) return;

    if (nonPrintJsType.includes(data?.file_type as PrintType)) {
      const pngs = await getPngMutate(id);

      if (!pngs?.length) return;

      printJS({
        printable: pngs.map(base64Image),
        type,
        showModal: true,
        base64: true,
        modalMessage: t('waiting'),
        imageStyle: 'width:680px;margin-left:20px'
      });

      return;
    }

    if (!printable) return;

    printJS({
      printable,
      type,
      showModal: true,
      modalMessage: t('waiting')
    });
  };

  const canPrint = (document: APIDocument) =>
    !!printType[document.file_type as keyof typeof printType];

  return { print, canPrint, loading: isLoading || isPngLoading };
};
