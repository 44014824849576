import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { StorageNotification } from './components/StorageNotification';
import {
  ThemeProvider,
  NotificationProvider,
  UserProvider,
  FoldersProvider,
  DownloadFilesProvider
} from 'contexts';
import { axiosHeadersUpdater } from './api/axios';

import { publicRoutes, routes } from './routes';

import { i18Next } from './i18n';

axiosHeadersUpdater();

i18Next();

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  });

  const allowPublicAccess =
    new URLSearchParams(window.location.search).get('token') &&
    ['/partner/delegations/'].some((value) => window.location.pathname.startsWith(value));

  return (
    <BrowserRouter>
      <ThemeProvider>
        <QueryClientProvider client={queryClient}>
          <NotificationProvider>
            <DownloadFilesProvider>
              {/* Temporary */}
              {/* <Alert
                message={<b>{t('mentenanteMessage')}</b>}
                type="error"
                style={{
                  background: 'red',
                  border: 'none',
                  borderRadius: 0,
                  width: '99.6vw',
                  textAlign: 'center'
                }}
              /> */}

              {allowPublicAccess ? (
                <Routes>
                  {publicRoutes.map(({ name, ...rest }) => (
                    <Route key={name} {...rest} />
                  ))}
                </Routes>
              ) : (
                <UserProvider>
                  <FoldersProvider>
                    <StorageNotification />
                    <Routes>
                      {routes.map(({ name, ...rest }) => (
                        <Route key={name} {...rest} />
                      ))}
                    </Routes>
                  </FoldersProvider>
                </UserProvider>
              )}
            </DownloadFilesProvider>
          </NotificationProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
