import styled from '@emotion/styled';
import { Space, Spin } from 'antd';
import { PropsWithChildren, ReactNode, useContext, useState } from 'react';
import { AxiosError } from 'axios';

import { NotificationContext, ThemeContext } from 'contexts';
import { CloseBig, DownloadAlt, FileExcel, TrashFull } from 'icons';
import { getNotificationError } from 'utils';

interface HeaderActionsInterface extends PropsWithChildren {
  onClear?: () => void;
  onDelete?: () => void;
  onDownload?: () => void;
  onDownloadExcel?: () => void;
}

type ActionBtnProps = {
  icon: ReactNode;
  activeButton?: boolean;
  action?: () => void | Promise<void>;
};

export const ActionBtn = ({ icon, activeButton, action }: ActionBtnProps) => {
  const { currentTheme } = useContext(ThemeContext);
  const { notification } = useContext(NotificationContext);
  const [isLoading, setIsLoading] = useState(false);

  const IconWrapper = styled.div`
    color: ${currentTheme[activeButton ? 'purple-75' : 'grey-70']};
    display: flex;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      color: ${currentTheme['colorPrimary']};
    }
  `;

  const run = async () => {
    setIsLoading(true);
    try {
      await action?.();
    } catch (err) {
      const error = err as AxiosError;
      notification.error({ message: getNotificationError(error) });
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Spin />;

  return action ? <IconWrapper onClick={run}>{icon}</IconWrapper> : null;
};

export const HeaderActions = ({
  onClear,
  onDelete,
  onDownload,
  onDownloadExcel,
  children
}: HeaderActionsInterface) => {
  return (
    <Space styles={{ item: { display: 'flex' } }} size={16}>
      <ActionBtn icon={<CloseBig />} action={onClear} />

      {children}

      <ActionBtn activeButton icon={<DownloadAlt />} action={onDownload} />
      <ActionBtn activeButton icon={<TrashFull />} action={onDelete} />
      <ActionBtn activeButton icon={<FileExcel />} action={onDownloadExcel} />
    </Space>
  );
};
