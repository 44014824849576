import { Button, Space, Switch, Typography } from 'antd';
import { ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useRerender, useThrottledCallback } from '@react-hookz/web';

import {
  AddDocumentsBtn,
  DeleteConfirmModal,
  FoldersTreeMenuSection,
  HeaderActions,
  Input,
  MainLayout
} from 'components';
import { onFilterChange, useDownloadFiles, useQueryParams } from 'hooks';
import { ThemeContext } from 'contexts';
import { APIDocument, ClientDocumentsFiltersValue, DocumentStatus } from 'types';
import { documents, documentsQueries } from 'api';
import { ChartParams, DocumentsParams } from 'api/documents.types';
import { dashDateFormat, dateTimeHyphenFormat, hasReadOnlyDocuments, withValues } from 'utils';
import { DocumentsMenuSection } from 'features/common/components';
import { DocumentsTable } from './DocumentsTable';
import { AllPartnersMenuSection, UploadedDocumentsMenuSection } from '.';

interface DocumentsContentWrapperInterface {
  filters: ClientDocumentsFiltersValue;
  onFiltersChange: onFilterChange<ClientDocumentsFiltersValue>;
  pageHeading: ReactNode;
  showFolderColumn?: boolean;
}

export const DocumentsContentWrapper = ({
  filters,
  onFiltersChange: onChange,
  pageHeading,
  showFolderColumn
}: DocumentsContentWrapperInterface) => {
  const { t } = useTranslation();
  const queryParams = useQueryParams<ChartParams & { documents_state: DocumentStatus }>();
  const [count, setCount] = useState(0);
  const [data, setData] = useState<APIDocument[]>([]);
  const [selectedDocuments, setSelectedDocuments] = useState<APIDocument[]>([]);
  const [downloadingFolder, setDownloadingFolder] = useState<number>();
  const [selectedAll, setSelectedAll] = useState(false);
  const [excludedRows, setExcludedRows] = useState<number[]>([]);
  const [isBulkDeleteModalOpen, setBulkDeleteModalOpen] = useState(false);
  const queryClient = useQueryClient();

  const onFiltersChange: onFilterChange<ClientDocumentsFiltersValue> = (data) =>
    onChange?.({ ...data, documents_archive: false, directory: null });

  const { currentTheme } = useContext(ThemeContext);

  const rerender = useRerender();

  const progressRef = useRef<Record<string, number>>({});
  const progress = progressRef.current;

  const throttledRerender = useThrottledCallback(rerender, [], 500);

  const setProgress = (newProgress: React.SetStateAction<Record<string, number>>) => {
    progressRef.current =
      newProgress instanceof Function ? newProgress(progressRef.current) : newProgress;

    throttledRerender();
  };

  const selectedCount = selectedAll ? count - excludedRows.length : selectedDocuments?.length;

  const hasAnyReadOnlyDocumentsSelected = hasReadOnlyDocuments(
    data,
    selectedAll && filters.documents_state
      ? data.map(({ id }) => id)
      : selectedDocuments.map(({ id }) => id)
  );

  const onClearSelection = () => {
    setSelectedAll(false);
    setSelectedDocuments([]);
    setExcludedRows([]);
  };

  const { sent_at_after, sent_at_before, ...restFilters } = filters;

  const sentAtFilters = {
    ...(sent_at_after && { sent_at_after: dayjs(sent_at_after).format(dashDateFormat) }),
    ...(sent_at_before && { sent_at_before: dayjs(sent_at_before).format(dashDateFormat) })
  };

  const queryDocumentParams = useMemo(
    () =>
      withValues({
        ...restFilters,
        ...sentAtFilters,
        state: queryParams.documents_state
      }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryParams.documents_state, filters]
  ) as ClientDocumentsFiltersValue;

  useEffect(() => {
    if (!!(excludedRows.length - count)) return;

    onClearSelection();
  }, [excludedRows.length, count]);

  useEffect(() => {
    onClearSelection();
  }, [filters.documents_state]);

  const { handleDownloadFiles } = useDownloadFiles();

  const { mutateAsync: downloadMutate, isLoading: isDownloadLoading } = useMutation(
    documents.downloadFiles,
    {
      onSuccess: (data) => {
        setDownloadingFolder(undefined);
        onClearSelection();
        handleDownloadFiles(data);
      }
    }
  );

  const { mutateAsync: downloadExcelMutate } = useMutation(documents.downloadExcelFiles, {
    onSuccess: (data) => {
      onClearSelection();
      handleDownloadFiles(data);
    }
  });

  const onChartEnabledToggle = () =>
    onFiltersChange({
      charts_enabled: !filters.charts_enabled
    });

  const onDownloadDocuments = async () => {
    const selectedDocumentsKeys = await selectedDocuments.map(({ id }) => id);
    if (selectedCount > 1 || selectedAll) {
      const dateAndTimestamp = await dayjs().format(dateTimeHyphenFormat);

      await downloadMutate({
        ...(queryDocumentParams as DocumentsParams),
        ids: selectedDocumentsKeys,
        exclude_ids: excludedRows,
        name: `SmartPortal-export-${dateAndTimestamp}.zip`
      });
    } else if (selectedCount === 1) {
      const data = await documents.getById(selectedDocuments?.[0].id);

      await downloadMutate({ ids: selectedDocumentsKeys[0], name: data.title });
    }
  };

  const onDownloadExcel = async () => {
    const selectedDocumentsKeys = selectedDocuments.map(({ id }) => id);

    const dateAndTimestamp = dayjs().format(dateTimeHyphenFormat);

    await downloadExcelMutate({
      ...(queryDocumentParams as DocumentsParams),
      ids: selectedDocumentsKeys,
      exclude_ids: excludedRows,
      name: `SmartPortal-export-${dateAndTimestamp}.xlsx`
    });
  };

  const invalidateDocumentQueries = () => {
    queryClient.invalidateQueries(documentsQueries.getList().queryKey);
    queryClient.invalidateQueries(documentsQueries.getClientStats().queryKey);
  };

  const deleteBulkMutation = useMutation(
    () =>
      documents.deleteBulk({
        ...queryDocumentParams,
        ids: selectedDocuments.map(({ id }) => id),
        exclude_ids: excludedRows
      }),
    {
      onSuccess: () => {
        invalidateDocumentQueries();
        setSelectedDocuments([]);
        setBulkDeleteModalOpen(false);
      }
    }
  );

  return (
    <MainLayout
      pageHeading={
        !selectedCount ? (
          pageHeading
        ) : (
          <HeaderActions
            onClear={() => {
              setSelectedDocuments([]);
              setExcludedRows([]);
              setSelectedAll(false);
            }}
            onDownload={onDownloadDocuments}
            onDelete={
              !hasAnyReadOnlyDocumentsSelected ? () => setBulkDeleteModalOpen(true) : undefined
            }
            onDownloadExcel={onDownloadExcel}
          >
            {selectedCount > 1
              ? t('selectedElements', {
                  count: selectedCount,
                  name: t('documents')
                })
              : t('selectedElement', {
                  count: selectedCount,
                  name: t('document')
                })}

            {selectedAll && !excludedRows.length ? (
              <Button type="link" onClick={onClearSelection}>
                {t('clearAll')}
              </Button>
            ) : (
              <Button
                type="link"
                onClick={() => {
                  setSelectedAll(true);
                  setExcludedRows([]);
                  setSelectedDocuments([]);
                }}
              >
                {t('selectAll', { count })}
              </Button>
            )}
          </HeaderActions>
        )
      }
      pageActions={
        <Space>
          <Space>
            {t('evolution')}
            <Switch checked={filters.charts_enabled} onChange={onChartEnabledToggle} />
          </Space>

          <Input
            prefix="search"
            style={{ width: 420 }}
            placeholder={t('searchDocuments')}
            value={filters.search}
            onChange={(e) => onChange({ search: e.target.value })}
          />

          <AddDocumentsBtn
            partnerId={filters.partner_ids as number}
            filters={filters}
            setProgress={setProgress}
          />
        </Space>
      }
      onChange={onFiltersChange}
      menuSections={[
        <DocumentsMenuSection filters={filters} onChange={onFiltersChange} />,
        <AllPartnersMenuSection onChange={onFiltersChange} />,
        <FoldersTreeMenuSection filters={filters} onFiltersChange={onChange} />,
        <UploadedDocumentsMenuSection filters={filters} onFiltersChange={onFiltersChange} />
      ]}
    >
      <DocumentsTable
        queryDocumentParams={queryDocumentParams}
        filters={filters}
        onFiltersChange={onFiltersChange}
        selectedDocuments={selectedDocuments}
        setSelectedDocuments={setSelectedDocuments}
        downloadMutate={downloadMutate}
        isDownloadLoading={isDownloadLoading}
        downloadingFolder={downloadingFolder}
        setDownloadingFolder={setDownloadingFolder}
        showFolderColumn={showFolderColumn}
        progress={progress}
        excludedRows={excludedRows}
        setExcludedRows={setExcludedRows}
        selectedAll={selectedAll}
        setCount={setCount}
        setData={setData}
      />

      <DeleteConfirmModal
        mutation={deleteBulkMutation}
        title={t('deleteDocuments')}
        open={isBulkDeleteModalOpen}
        onCancel={() => setBulkDeleteModalOpen(false)}
      >
        <Typography.Text style={{ color: currentTheme['grey-40'], fontSize: '0.875rem' }}>
          {t('approveDocumentBulkDelete', {
            length: selectedAll ? count - excludedRows.length : selectedDocuments.length
          })}
        </Typography.Text>
      </DeleteConfirmModal>
    </MainLayout>
  );
};
